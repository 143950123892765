import React, { useState } from 'react'

import styles from './newsletter-form.module.scss'
import Container from '../container/container'

const NewsletterForm = () => {
  const [email, setEmail] = useState('')

  return (
    <Container>
      <div className={styles.newsletter}>
        <form
          method="post"
          action="/newsletter-success"
          data-netlify="true"
          netlify-honeypot="bot-field"
          name="Max2 Security Newsletter"
          className={styles.form}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="Max2 Security Newsletter" />
          <input
            type="email"
            className={styles.textInput}
            name="email"
            placeholder="Your email address"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
          <div className={styles.formActions}>
            <button className={styles.submitButton}>Subscribe</button>
          </div>
        </form>
        <p className={styles.disclaimer}>
          By submitting your email address, you agree to receive newsletters from 
          Max<sup>2</sup> Security LLC. We will not use your information for any 
          other purpose. We do not sell or share your information with anyone.
        </p>
      </div>
    </Container>
  )
}
 
export default NewsletterForm
