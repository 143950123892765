import React from 'react'

import Layout from '../components/layout/layout'
import SubHero from '../components/sub-hero/sub-hero'
import NewsletterForm from '../components/newsletter-form/newsletter-form'

const NewsletterPage = () => {
  return (
    <div>
      <Layout title="Cybersecurity Newsletter">
        <SubHero
          title="Cybersecurity Newsletter"
          subtitle="Sign up for Max2 Security's summary of cybersecurity 
            alerts, important industry news, and executive threat analysis."
        />
        <div style={{ height: '5rem' }} />
        <NewsletterForm />
      </Layout>
    </div>
  )
}

export default NewsletterPage
