import React from 'react'
import PropTypes from 'prop-types'

import Container from '../container/container'
import styles from './sub-hero.module.scss'

const SubHero = props => {
  return (
    <div className={styles.subHero}>
      <Container>
        <h1 className={styles.title}>{props.title}</h1>
        <h3 className={styles.subtitle}>{props.subtitle}</h3>
      </Container>
    </div>
  )
}
 
export default SubHero

SubHero.propTypes = {
  // title: PropTypes.string,

}

SubHero.defaultProps = {
  // title: null,

}
